<template>
  <v-form v-model="formValid">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <DatePicker
          v-model="openedAt"
          :rules="[presenceRule]"
          dense
          filled
          label="Data Storno"
          hide-details="auto"
          @input="updateField('openedAt', $event)"
        ></DatePicker>
      </v-col>
      <v-col class="pl-1">
        <NumericKeyboardInput
          v-model="amount"
          :rules="[presenceRule]"
          filled
          :dense="false"
          label="Ammontare"
          hide-details="auto"
          @input="updateField('amount', $event)"
          :disabled="!isNew || (Array.isArray(billLog) && billLog.length > 0)"
        ></NumericKeyboardInput>
      </v-col>
      <v-col class="pl-2">
        <CustomersAutocomplete
          v-model="customer"
          return-object
          clearable
          label="Cliente"
          @input="updateField('customer', $event)"
        ></CustomersAutocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-textarea
          v-model="reason"
          label="Motivazione"
          filled
          hide-details="auto"
          @input="updateField('reason', $event)"
        ></v-textarea>
      </v-col>
    </v-row>
    <template v-if="isNew">
      <v-subheader class="font-italic font-weight-light py-1">Documento commerciale di riferimento</v-subheader>
      <PaymentTransactionsTable
        v-model="billLog"
        :customer="customer"
        :bus="billLogsTableBus"
        @input="handleBillSelection"
        :showDialogRowClicked="false"
      ></PaymentTransactionsTable>
      <v-autocomplete
        v-model="serviceChosen"
        class="mt-2"
        label="Servizi da stornare"
        :items="serviceChoices"
        filled
        dense
        multiple
        item-key="id"
        item-text="text"
        return-object
        hide-details="auto"
        @input="handleServiceToConsiderChange"
      ></v-autocomplete>
    </template>
    <v-subheader class="font-italic font-weight-light py-1">Pagamento</v-subheader>
    <v-row no-gutters class="py-1">
      <v-col>
        <v-autocomplete
          v-model="documentType"
          label="Pagato con"
          filled
          dense
          :items="[
            { text: 'Contanti', value: 'receipt' },
            { text: 'Fattura', value: 'invoice' },
          ]"
          hide-details="auto"
          @input="updateField('documentType', $event)"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue'
import DatePicker from '@/components/common/DatePickerCommon.vue'
import CustomersAutocomplete from '@/components/common/CustomersAutocomplete.vue'
import PaymentTransactionsTable from '@/components/areas/payments/paymentTransactions/PaymentTransactionsTable.vue'

export default {
  name: "GeneralFormTab",
  components: {
    DatePicker,
    NumericKeyboardInput,
    CustomersAutocomplete,
    PaymentTransactionsTable
  },
  data: function() {
    return {
      openedAt: undefined,
      amount: undefined,
      customer: undefined,
      operator: undefined,
      reason: undefined,
      loadingPaymentDebit: false,
      type: undefined,
      documentType: undefined,
      billLog: undefined,
      formValid: false,
      isNew: false,
      serviceChosen: [],
      billLogsTableBus: new Vue(),
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  mounted: function() {
    this.handleObjectChanges(paymentDebitForm.paymentDebit)

    var self = this
    paymentDebitForm.on('update', function(data) {
      self.handleObjectChanges(data.paymentDebit)
    })

    this.isNew = paymentDebitForm.isNew
    paymentDebitForm.on('update-is-new', function(isNew) {
      self.isNew = isNew
    })
  },
  methods: {
    handleObjectChanges(paymentDebit) {
      if(!!paymentDebit) {
        if(!!paymentDebit.openedAt) {
          this.openedAt = new Date(paymentDebit.openedAt)
        }

        if(!!paymentDebit.amount) {
          this.amount = Number(paymentDebit.amount)
        }

        if(!!paymentDebit.type) {
          this.type = paymentDebit.type

          if(paymentDebit.type == 'customer') {
            this.customer = !!paymentDebit.customer ? paymentDebit.customer : undefined
          } else if(paymentDebit.type == 'bill') {
            this.customer = !!paymentDebit.bills && paymentDebit.bills.length > 0 && !!paymentDebit.bills[0].billCustomers && paymentDebit.bills[0].billCustomers.length > 0 && !!paymentDebit.bills[0].billCustomers[0].customer ? 
              paymentDebit.bills[0].billCustomers[0].customer : undefined
          } else if(paymentDebit.type == 'operator') {
            this.operator = !!paymentDebit.operator ? paymentDebit.operator : undefined
          }
        }

        if(!!paymentDebit.reason) {
          this.reason = paymentDebit.reason
        }

        if(!!paymentDebit.documentType) {
          this.documentType = paymentDebit.documentType
        }
      }
    },
    updateField(key, value) {
      paymentDebitForm.updateField(key, value)
    },
    handleBillSelection(newBillLog) {
      if(!!newBillLog && Array.isArray(newBillLog) && newBillLog.length > 0) {
        this.updateField('bill', {
          id: newBillLog[0].billId
        })
      }
    },
    handleServiceToConsiderChange(newValue) {
      this.amount = this.sumCurrencyAmounts(...newValue.map((el) => {
        return el.totalCost
      }))
      this.updateField('amount', this.amount)
    },
    sumCurrencyAmounts(...amounts) {
      if(!amounts) {
        return 0
      } else if(amounts.length == 0) {
        return 0
      } else {
        let accumulator = 0
        for(let i = 0; i < amounts.length; i += 1) {
          if(!!amounts[i]) {
            accumulator = Number(Number(Number(Number(accumulator).toFixed(2)) + Number(Number(amounts[i]).toFixed(2))).toFixed(2))
          }
        }
        return accumulator
      }
    },
  },
  watch: {
    formValid(newVal) {
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    customer() {
      if(this.isNew) {
        this.$nextTick(() => {
          this.billLogsTableBus.$emit('reload')
          this.billLog = []
        })
      }
    },
    billLog() {
      if(this.billLog.length == 0 && this.isNew) {
        this.amount = 0
        this.updateField('amount', this.amount)
        this.serviceChosen = []
      }
    }
  },
  computed: {
    serviceChoices() {
      if(!this.billLog || this.billLog.length == 0 || !this.billLog[0].info || !this.billLog[0].info.items) {
        return []
      } else {
        let choices = this.billLog[0].info.items.map((el, index) => {
          let text
          if(!!el.name) {
            text = el.name
          } else if(!!el.description) {
            text = el.description
          }

          let price
          if(!!el.price) {
            price = Number(el.price)
          } else if(!!el.priceSelling) {
            price = Number(el.priceSelling)
          } else {
            price = 0
          }

          let quantity
          if(!!el.quantity) {
            quantity = Number(el.quantity)
          } else {
            quantity = 0
          }

          let totalCost = Number(Number(price * quantity).toFixed(2))

          return {
            id: index,
            text: text,
            totalCost: totalCost
          }
        })

        return choices
      }
    }
  }
};
</script>